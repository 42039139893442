const multicountryLocales = [
  "en-bg",
  "en-cz",
  "en-dk",
  "en-ee",
  "en-fi",
  "en-hr",
  "en-hu",
  "en-lt",
  "en-lu",
  "en-lv",
  "en-ro",
  "en-no",
  "en-se",
];

const middleEastLocales = ["ar-qa", "en-qa", "ar-kw", "en-kw", "ar-bh", "en-bh", "ar-om", "en-om", "ar-jo", "en-jo"];

const enabledLocales = [
  "ar-ae",
  "en-ae",
  "en-us",
  "it-it",
  "fr-fr",
  "de-de",
  "en-gb",
  "nl-nl",
  "de-ch",
  "it-ch",
  "fr-ch",
  "es-es",
  "fr-be",
  "nl-be",
  "pt-pt",
  "pl-pl",
  "en-ie",
  "de-at",
  ...multicountryLocales,
];

const localeConfig = {
  contentfulLocales: enabledLocales.reduce((acc, l) => {
    const parts = l.split("-");

    if (multicountryLocales.includes(l)) {
      acc[l] = "en-MULTI";
    } else {
      acc[l] = `${parts[0]}-${parts[1].toUpperCase()}`;
    }

    return acc;
  }, {}),
  enabledLocales,
  middleEastLocales,
  multicountryLocales,
  nextLocales: ["default", ...enabledLocales],
};

module.exports = localeConfig;

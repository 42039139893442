import {
  KikGenericLoggerMethodKeys,
  KikLoggerService,
  KikLoggingLevels,
  kikNoop,
} from "@kikocosmeticsorg/uc-api-nest-common-fe";
import type TransportStream from "winston-transport";

import CommonUtils from "~/utils/common-utils";

export default class Logger extends KikLoggerService {
  static get instance() {
    return this.INSTANCE;
  }

  protected static _enableDatadog: boolean = CommonUtils.parseBoolean(process.env.NEXT_PUBLIC_DATADOG_ENABLED);

  preprocessArgs(level: KikGenericLoggerMethodKeys, ...args: any[]): any[] {
    const now = new Date();

    return [
      `${now.toISOString()} - ${args.shift()}`.trim(),
      ...args.map((value) => {
        return {
          tags: [this._appName, level],
          timestamp: +now,
          sessionId: this.sessionId,
          metadata: this.filterSensitiveData(value),
        };
      }),
    ];
  }

  /**
   * @override
   * @param newValue
   * @private
   */
  protected async _updateLevel(newValue: KikLoggingLevels = KikLoggingLevels.WARN) {
    const staticSelf = this.constructor as typeof Logger;
    staticSelf.describe().forEach((key) => {
      this[key] = kikNoop;
    });
    Logger._level = newValue;

    let logger: Parameters<typeof this._setup>[0] = console;

    if (typeof window !== "undefined") {
      // Client-side logs
      if (staticSelf._enableDatadog) {
        logger = await import("@datadog/browser-logs").then((lib) => lib.datadogLogs);
      }
    } else {
      // Server-side logs
      const winston = await import("winston");
      const packageJson = await import("../../../package.json");

      let transports: TransportStream[] = [new winston.transports.Console({ level: "debug" })];

      if (staticSelf._enableDatadog) {
        const params = new URLSearchParams({
          "dd-api-key": process.env.DATADOG_API_KEY!,
          "dd-source": "nodejs",
          service: process.env.NEXT_PUBLIC_DATADOG_SERVICE_BACKEND!,
          ddtags: `env:${process.env.NEXT_PUBLIC_DATADOG_ENV},version:${packageJson.version}`,
        });

        transports.push(
          new winston.transports.Http({
            host: "http-intake.logs.datadoghq.eu",
            path: `/api/v2/logs?${params}`,
            ssl: true,
          })
        );
      }

      logger = winston.createLogger({
        level: "debug",
        exitOnError: true,
        format: winston.format.json(),
        transports,
      });
    }

    console.info(`Logger: update level to ${newValue}, using ${staticSelf._enableDatadog ? "datadog" : "console"}`);
    this._setup(logger);
  }
}

Logger.init({
  level:
    KikLoggingLevels[KikLoggingLevels[+process.env.NEXT_PUBLIC_LOG_LEVEL!] as keyof typeof KikLoggingLevels] ||
    KikLoggingLevels.OFF,
  enableSessionId: !0,
  enablePreprocessing: !0,
});

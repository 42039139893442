import CommonUtils from "~/utils/common-utils";

// Use ApiUtils.interpolateParams to replace your params dynamically
const endpoints = {
  authApplyActionCode: `auth/apply-action-code/`,
  authCheckActionCode: `auth/check-action-code/`,
  authCustomer: `auth/customer/`,
  authEmailUpdate: `auth/email-update/`,
  authEmailUpdateCompletion: `auth/email-update-completion`,
  authPasswordReset: `auth/password-reset/`,
  authProspect: `auth/prospect/`,
  authSendEmailVerification: `auth/send-email-verification/`,
  authSocialCustomer: "auth/social-customer/",
  authSignUpCompletion: `auth/sign-up-completion/`,
  authSignUpProspectCompletion: `auth/sign-up-prospect-completion/`,
  authUnsubscribe: `auth/unsubscribe/`,
  captainWalletUrl: `loyalty/captain-wallet-url/`,
  giftcardActivate: `private/giftcard/activate/`,
  giftcardCancel: `private/giftcard/cancel/`,
  giftcardInfo: `private/giftcard/info/`,
  giftcardIssue: `private/giftcard/issue/`,
  giftcardPurchase: `private/giftcard/purchase/`,
  giftcardRefund: `private/giftcard/refund/`,
  legacyDownloadGiftcard: `legacy/giftcards/download/`,
  legacyOrders: `legacy/%s/orders/`,
  legacyOrderDetail: `legacy/%s/orders/%s/`,
  shippingOptimalCarrier: "shipping/optimal-carrier/",
  shippingPods: "shipping/pods/",
  socialWall: `social-wall/`,
  validateEmail: "validate-email/",
};

const endpointsV2 = {
  authCustomer: `auth/customer/`,
  authLoginAsCustomer: `auth/login-as-customer/`,
  authValidateEmail: `auth/validate-email/`,
  authSendEmailVerification: `auth/send-email-verification/`,
  authSubscriptions: `auth/subscriptions/`,
  giftcardCheckBalance: `epipoli/check-balance/`,
};

type ApiEndpointKeys = keyof typeof endpoints;

type ApiEndpointKeysV2 = keyof typeof endpointsV2;

export class ApiConstants {
  static get baseUrl(): string {
    return process.env.NEXT_PUBLIC_API_BASE_URL || "/api/next";
  }

  static get baseUrlV2(): string {
    return process.env.NEXT_PUBLIC_API_V2_BASE_URL || "/api/v2";
  }

  static get privateBaseUrl(): string {
    return CommonUtils.buildPath(this.baseUrl, "private");
  }

  static readonly endpoints: Record<ApiEndpointKeys, string> = this._mapEndpoints<ApiEndpointKeys>(
    endpoints,
    this.baseUrl
  );

  static readonly endpointsV2: Record<ApiEndpointKeysV2, string> = this._mapEndpoints<ApiEndpointKeysV2>(
    endpointsV2,
    this.baseUrlV2
  );

  private static _mapEndpoints<T extends string = string>(endpointsMap: Record<T, string>, baseUrl: string) {
    return CommonUtils.mapObjectValues(endpointsMap, (v) => [baseUrl, v].join("/"));
  }
}

import { useQuery } from "@tanstack/react-query";
// @ts-expect-error - `cookie-cutter` has no exported types
import Cookie from "cookie-cutter";
import Image from "next/image";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { countryDetectReadyState, sequentialLoadingState } from "~/atoms/sequentialLoading";
import Dialog from "~/components/common/dialog";
import Icon from "~/components/common/icon";
import Constants from "~/utils/constants";
import crossCountryUtils from "~/utils/crossCountry-utils";
import { CultureLink } from "~/utils/locale-utils";

import countries from "../../../../public/json/countries.json";
import styles from "./country-detect-modal.module.scss";

type Props = {};

const ALL_COUNTRIES = countries.continents.flatMap((c) => c.countries);

export default function CountryDetectModal(props: Props) {
  const t = useTranslations();
  const router = useRouter();
  const [isOpen, setOpen] = useState(false);
  // Recoil state that tells if the component is loaded
  const setCountryDetectLoaded = useSetRecoilState(sequentialLoadingState);
  const sequentialLoadingReady = useRecoilValue(countryDetectReadyState);

  const query = useQuery({
    queryKey: ["detectCountry"],
    staleTime: Infinity,
    cacheTime: Infinity,
    queryFn: () =>
      fetch("/api/next/edge/detect-country")
        .then((res) => res.json())
        .then((res) => res as { country: string | null }),
    enabled: !!router.locale && router.locale !== "default",
  });

  useEffect(() => {
    if (query.data && sequentialLoadingReady) {
      const current = crossCountryUtils.getCurrentCountryCode(router).toLowerCase();
      const detected = query.data.country?.toLowerCase() ?? "gb";

      if (current !== detected) {
        const cookie = Cookie.get("country-detect");

        if (!cookie || cookie !== `${current}-${detected}`) {
          setOpen(true);
        }
      }
      setCountryDetectLoaded((current) => ({ ...current, countryDetect: true }));
    }
  }, [sequentialLoadingReady, query.data, router, setCountryDetectLoaded]);

  useEffect(() => {
    if (isOpen) {
      const didomiHost = document.getElementById("didomi-host");
      if (didomiHost) {
        didomiHost.ariaHidden = "true";
      }
    }
  }, [isOpen, sequentialLoadingReady]);

  const current = crossCountryUtils.getCurrentCountryCode(router).toLowerCase();
  const currentCountry = ALL_COUNTRIES.find((c) => c.countryCode.toLowerCase() === current.toLowerCase());

  if (!currentCountry) {
    return null;
  }

  if (!query.data) {
    return null;
  }

  const detected = query.data.country?.toLowerCase() ?? "gb";
  const detectedCountry =
    ALL_COUNTRIES.find((c) => c.countryCode.toLowerCase() === detected.toLowerCase()) ||
    ALL_COUNTRIES.find((c) => c.countryCode.toLowerCase() === "gb")!;

  const defaultCulture = detectedCountry.cultures.find((c) => c.default) ?? detectedCountry.cultures[0];

  function stayInCurrentCountry() {
    Cookie.set("country-detect", `${current}-${detected}`, {
      path: "/",
    });

    setOpen(false);
    const didomiHost = document.getElementById("didomi-host");
    if (didomiHost) {
      didomiHost.ariaHidden = null;
    }
    document.getElementById(Constants.USABLENET_LINK_KEY)?.focus();
  }

  return (
    <Dialog
      isOpen={isOpen}
      title={
        <div className={styles.title}>
          <Icon name="country" />
          <span>{t("generic.check_your_country")}</span>
        </div>
      }
      onClose={() => stayInCurrentCountry()}
      customStyles={{ wrapper: styles.countryWrapperDialog, overlay: styles.overlay }}
      shouldCloseOnOverlayClick={false}
      shouldFocusAfterRender={false}
    >
      <div>
        <p className={styles.updateCountry}>
          {t.rich("generic.update_your_country", {
            country: detectedCountry.name,
            strong: (chunks) => <strong className={styles.highlightedCountry}>{chunks}</strong>,
            br: (chunks) => <br />,
          })}
        </p>
        <CultureLink
          country={detectedCountry}
          culture={defaultCulture}
          className={styles.wrapperCTA}
          aria-label={
            t("generic.yes") +
            " " +
            t("generic.go_to_country_website", {
              country: detectedCountry.name,
            })
          }
        >
          <Image
            unoptimized
            width={28}
            height={28}
            alt=""
            aria-hidden
            src={`/next/assets/flags/${detectedCountry.countryCode}.svg`}
            className={styles.flag}
          />
          <span className={styles.wrapperAnswer} aria-hidden>
            <span className={styles.answer}>{t("generic.yes")}</span>
            <span className={styles.paragraph}>
              {t("generic.go_to_country_website", {
                country: detectedCountry.name,
              })}
            </span>
          </span>
          <Icon name="arrow-right" width={16} height={16} className={styles.icon} />
        </CultureLink>
        <button
          onClick={() => stayInCurrentCountry()}
          className={styles.wrapperCTA}
          aria-label={t("generic.no") + " " + t("generic.stay_in_country", { country: currentCountry.name })}
        >
          <Image
            unoptimized
            width={28}
            height={28}
            alt=""
            aria-hidden
            src={`/next/assets/flags/${currentCountry.countryCode}.svg`}
            className={styles.flag}
          />
          <span className={styles.wrapperAnswer} aria-hidden>
            <span className={styles.answer}>{t("generic.no")}</span>
            <span className={styles.paragraph}>{t("generic.stay_in_country", { country: currentCountry.name })}</span>
          </span>
          <Icon name="arrow-right" width={16} height={16} className={styles.icon} />
        </button>
      </div>
    </Dialog>
  );
}
